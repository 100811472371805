import { useRouter } from "next/router";
import Icon from "@/components/ui/Icon";

import ByCategoryReportsNavbar from "./ByCategoryReportsNavbar";
import ByTeacherReportsNavbar from "./ByTeacherReportsNavbar";
import MasterDataNavbar from "./MaterDataNavbar";
import ReportsNavbar from "./ReportsNavbar";
import UsersNavbar from "./UsersNavbar";
import Invoice from "../Invoice";
import LoadingSpinner from "../ui/LoadingSpinner";
import supabase from "@/utils/supabase";
import { useQuery } from "@tanstack/react-query";
import useGetUserMe from "@/hooks/useGetUserMe";

const PageLayout = ({ children }) => {
  const router = useRouter();

  if (router.pathname.startsWith("/payer-dashboard")) {
    return (
      <Wrapper>
        {/* <PayerDashNavbar /> */}
        {children}
      </Wrapper>
    );
  }
  if (router.pathname.startsWith("/teacher-dashboard")) {
    return (
      <Wrapper>
        <InvoiceSubmissionProtector>{children}</InvoiceSubmissionProtector>
      </Wrapper>
    );
  }
  if (router.pathname.startsWith("/admin-dashboard/master-data")) {
    return (
      <Wrapper>
        <h1 className="H1">Master Data</h1>
        <MasterDataNavbar />
        {children}
      </Wrapper>
    );
  }
  if (router.pathname.startsWith("/admin-dashboard/reports/by-teacher")) {
    return (
      <Wrapper>
        <h1 className="H1">
          Reports
          {` `}
          <Icon name="i-ph-caret-right" />
          {` `}
          <span className="fw-200">By Teacher</span>
        </h1>
        <div className="space-y-4">
          <ReportsNavbar />
          <ByTeacherReportsNavbar />
        </div>
        {children}
      </Wrapper>
    );
  }
  if (router.pathname.startsWith("/admin-dashboard/reports/by-category")) {
    return (
      <Wrapper>
        <h1 className="H1">
          Reports
          {` `}
          <Icon name="i-ph-caret-right" />
          {` `}
          <span className="fw-200">By Category</span>
        </h1>
        <div className="space-y-4">
          <ReportsNavbar />
          <ByCategoryReportsNavbar />
        </div>
        {children}
      </Wrapper>
    );
  }
  if (router.pathname.startsWith("/admin-dashboard/reports")) {
    return (
      <Wrapper>
        <h1 className="H1">Reports</h1>
        <ReportsNavbar />
        {children}
      </Wrapper>
    );
  }
  if (router.pathname.startsWith("/admin-dashboard/users")) {
    return (
      <Wrapper>
        <h1 className="H1">User Management</h1>
        <UsersNavbar />
        {children}
      </Wrapper>
    );
  }
  return children;
};

const Wrapper = ({ children }) => {
  return <div className="space-y-8">{children}</div>;
};

export default PageLayout;

const InvoiceSubmissionProtector = ({ children }) => {
  const userMeQ = useGetUserMe();
  const invoiceSubmittedQ = useQuery({
    queryKey: ["invoiceSubmitted-1"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("teacher")
        .select(`id, invoiceSubmitted`)
        .eq("id", userMeQ.data?.teacher.id)
        .single();
      if (error) throw error;
      return data.invoiceSubmitted;
    },
    enabled: !!userMeQ.data?.teacher?.id,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  if (userMeQ.isLoading)
    return (
      <div className="h-full flex flex-col gap-6 jc ac ">
        <LoadingSpinner />
      </div>
    );

  if (invoiceSubmittedQ.isLoading) {
    return (
      <div className="h-full flex flex-col gap-6 jc ac ">
        <LoadingSpinner />
      </div>
    );
  }
  if (invoiceSubmittedQ.data) {
    return children;
  }
  if (!invoiceSubmittedQ.data) {
    return <Invoice />;
  }

  return children;
};
