import { HTMLProps } from "@/types";
import { forwardRef, useId } from "react";

import Feild, { FeildProps } from "./Feild";

const classes = {
  inputElement: {
    base: `px-0.75em  py-0.375em bg-transparent focus:outline-transparent line-height-1`,
    disabled: "",
  },
};

type InputProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setValue?: (val: string) => void;
} & HTMLProps<"input">;

type Ref = HTMLInputElement;
type AllProps = React.ComponentPropsWithoutRef<"input"> & InputProps & Omit<FeildProps, "children" | "id">;

const Input = forwardRef<Ref, AllProps>(function (
  {
    hint,
    label,
    name,
    required,
    prefix,
    suffix,
    disabled,
    errorMessage,
    hasErrorMessage = true,
    setValue,
    onChange,
    id: idFromProps,
    ...inputProps
  },
  ref
) {
  const generatedIdId = useId();
  const id = idFromProps ?? generatedIdId;
  const hintId = hint ? `${id}-hint` : "";
  const errorMessageId = errorMessage ? `${id}-error-message` : "";
  return (
    <Feild
      hint={hint}
      label={label}
      errorMessage={errorMessage}
      hasErrorMessage={hasErrorMessage}
      disabled={disabled}
      suffix={suffix}
      prefix={prefix}
      required={required}
      name={name}
      id={id}
    >
      <input
        {...inputProps}
        ref={ref}
        name={name}
        id={id}
        className={` ${classes.inputElement.base}  ${disabled && classes.inputElement.disabled} `}
        aria-describedby={`${errorMessageId} ${hintId}`}
        aria-invalid={!!errorMessage}
        onChange={(e) => {
          setValue?.(e.target.value);
          onChange?.(e);
        }}
      />
    </Feild>
  );
});

Input.displayName = "Input";
export default Input;
