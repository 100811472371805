import { useEffect, useState } from "react";

type InputDebouncedProps = {
  value: string;
  onChange: (value: string) => void;
  debounceDuration?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">;

// A debounced input react component. For performance reasons the value changes after half a second.
export default function InputDebaounced({
  value: valueFromProps,
  onChange,
  debounceDuration = 500,
  ...props
}: InputDebouncedProps) {
  const [_value, setValue] = useState(valueFromProps);

  useEffect(() => {
    setValue(valueFromProps);
  }, [valueFromProps]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(_value);
    }, debounceDuration);

    return () => clearTimeout(timeout);
  }, [_value, debounceDuration]);

  return (
    <input
      {...props}
      // defaultValue={valueFromProps}
      value={_value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
