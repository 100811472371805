import { renderNoData } from "@/components/RenderQ";
import { TableR } from "@/components/Table";
import { DEFAULT_TEACHER_COMMISSION } from "@/constants";
import { frmCAD } from "@/utils/formatter";
import { useMonths } from "@/components/MonthsProvider";
import { calcSessionCost } from "@/pages/teacher-dashboard/payments";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useGetUserMe from "@/hooks/useGetUserMe";
import { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import toast from "react-hot-toast";

const Invoice = () => {
  const months = useMonths();
  const selectedMonth = months[1];
  const queryClient = useQueryClient();
  const [isInvoiceConfirmed, setIsInvoiceConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const saveAsPDF = async () => {
    setIsSubmitting(true);
    const invoiceElement = document.getElementById("invoice");
    if (!invoiceElement) return;
    const desiredWidth = Math.max(document.documentElement.clientWidth, 850);

    const canvas = await html2canvas(invoiceElement, {
      scale: 0.8,
      width: desiredWidth, // include padding
      height: invoiceElement.clientHeight, // include padding
      scrollY: -window.scrollY,
    });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a5");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    const pdfBlob = pdf.output("blob");
    const userNameAndDate =
      userMeQ?.data && userMeQ.data.firstname + " " + userMeQ.data.lastname + " " + selectedMonth.yearMonth;
    async function uploadFile(file) {
      const { data, error }: any = await supabase.storage.from("invoice").upload(`${userNameAndDate}.pdf`, file);
      if (error) {
        if (error.statusCode === "409") {
          const { error: invoiceSubmittedError } = await supabase
            .from("teacher")
            .update({ invoiceSubmitted: true })
            .eq("id", userMeQ.data?.teacher.id);

          if (invoiceSubmittedError) {
            toast.error("Error updating invoiceSubmitted: " + invoiceSubmittedError.message);
          } else {
            queryClient.invalidateQueries({ queryKey: ["invoiceSubmitted-1"] });
          }
        } else {
          toast.error("Error uploading invoice: " + error.message);
        }
      } else {
        const { error: invoiceSubmittedError } = await supabase
          .from("teacher")
          .update({ invoiceSubmitted: true })
          .eq("id", userMeQ.data?.teacher.id);

        if (invoiceSubmittedError) {
          toast.error("Error updating invoiceSubmitted: " + invoiceSubmittedError.message);
        } else {
          queryClient.invalidateQueries({ queryKey: ["invoiceSubmitted-1"] });
        }
      }
    }
    uploadFile(pdfBlob);
  };

  const supabase = useSupabaseClient();
  const userMeQ = useGetUserMe();
  const teacherName = userMeQ?.data && userMeQ.data.firstname + " " + userMeQ.data.lastname;

  const sessionQ = useQuery({
    queryKey: ["sessionTeacherDash-4"],
    enabled: !!userMeQ.data?.teacher.id,
    queryFn: async () => {
      const { data, error } = await supabase
        .from("session")
        .select(
          `
      rateMultiplierInSheet, isHeld, date, id,
    course!inner  (
      id, requestedSessionDurationInMinute, topicId , topic(name), numberOfStudents, isWorkshop,
      courseStudent (id, student(user(firstname, lastname))),
      teacher ( id , address, commissionPercentage,
        user ( id , firstname , lastname, firstnameFa , lastnameFa),
        expertise ( id , sessionPriceInCAD , sessionDurationOnWebsiteInMinute, startDate , endDate, topicId, teacherId )
      )
    ),
    packagePurchased (
      id , numberOfSessions ,
      expertise ( id,  sessionPriceInCAD , sessionDurationOnWebsiteInMinute, startDate , endDate, topicId, teacherId )
    )
    `
        )
        .filter("course.teacherId", "eq", userMeQ.data?.teacher.id)
        .filter("course.isWorkshop", "eq", false);
      if (error) throw error;
      return data;
    },
  });

useEffect(() => {
  const updateInvoiceSubmitted = async () => {
    if (sessionQ.data) {
      const monthSessions = sessionQ.data.filter((s) => selectedMonth.includes(s.date));
      if (monthSessions.length === 0) {
        const { error: invoiceSubmittedError } = await supabase
          .from("teacher")
          .update({ invoiceSubmitted: true })
          .eq("id", userMeQ.data?.teacher.id);

        if (invoiceSubmittedError) {
          toast.error("Error updating invoiceSubmitted: " + invoiceSubmittedError.message);
        } else {
          queryClient.invalidateQueries({ queryKey: ["invoiceSubmitted-1"] });
        }
      }
    }
  };

  updateInvoiceSubmitted();
}, [sessionQ.data]);

  const giftQ = useQuery({
    queryKey: ["giftTeacherDash-2"],
    enabled: !!userMeQ.data?.teacher.id,
    queryFn: async () => {
      const { data, error } = await supabase
        .from("giftForTeacher")
        .select(`*`)
        .eq("teacherId", userMeQ.data?.teacher.id);
      if (error) throw error;
      return data;
    },
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsInvoiceConfirmed(event.target.checked);
  };

  return (
    <>
      <p className="info-line mx-10 mt-10">To access your dashboard, please check and confirm the below invoice.</p>
      <div className="px-10 py-10" id="invoice">
        {renderNoData(sessionQ) ?? renderNoData(giftQ) ?? (
          <>
            <PaymentsTable
              userMeQ={userMeQ}
              selectedMonth={selectedMonth}
              sessions={sessionQ.data}
              gifts={giftQ.data}
              teacherName={teacherName}
            />
            <div className="flex gap-4 items-center">
              <input
                type="checkbox"
                id="confirm-invoice"
                checked={isInvoiceConfirmed}
                onChange={handleCheckboxChange}
                className="transform scale-150"
              />
              <label htmlFor="confirm-invoice" className="text-xl font-bold">
                I confirm this invoice through the checkbox.
              </label>
            </div>
          </>
        )}
      </div>
      <button onClick={saveAsPDF} disabled={!isInvoiceConfirmed || isSubmitting} className="mb-10 mx-10 btn-prm">
        {isSubmitting ? "Submitting..." : "Send invoice"}
      </button>
    </>
  );
};

export default Invoice;

const PaymentsTable = ({ sessions, gifts, selectedMonth, userMeQ, teacherName }) => {
  const monthSessions = sessions.filter((s) => selectedMonth.includes(s.date));
  const monthGifts = gifts.filter((g) => selectedMonth.includes(g.date));

  return (
    <div className="space-y-6">
      <div className="space-y-2"></div>
      {monthSessions && monthSessions.length > 0 && (
        <PageContent
          selectedMonth={selectedMonth}
          teacherName={teacherName}
          teacherAddress={sessions[0].course.teacher.address}
          monthGifts={monthGifts}
        >
          {monthSessions.map((se) => {
            let row = { ...se };

            delete row.packagePurchased;
            delete row.course;

            row = {
              date: new Date(row.date),
              subject: se.course.topic.name,
              ...calcSessionCost(se),
            };
            delete row.Students;
            delete row["Was Session Held"];
            delete row.hasPackage;
            const commission = userMeQ.data.teacher.commissionPercentage ?? DEFAULT_TEACHER_COMMISSION;

            row["Cost"] = frmCAD(row["Cost"] * (100 - commission) * 0.01);

            return row;
          })}
        </PageContent>
      )}
    </div>
  );
};

const PageContent = ({ children, selectedMonth, teacherName, teacherAddress, monthGifts }) => {
  const pageId = window.location.pathname;
  const rows = children;
  // please calculate the sum of gifts using reduce
  const giftsSum = monthGifts.length > 0 ? monthGifts.reduce((acc, gift) => acc + gift.amountInCAD, 0) : 0;

  return (
    <>
      <div>
        <div className="italic c-sand11">
          <p>
            Date: {selectedMonth.yearMonth.split(" ")[1]} 25th, {selectedMonth.yearMonth.split(" ")[0]}
          </p>
          <p>From:</p>
          <p>{teacherName}</p>
          {teacherAddress && <p>{teacherAddress}</p>}
          <p>To:</p>
          <p>Darsoon Inc.</p>
          <p>47 Brunel Street, Maple, ON, L6A 0R3</p>
          <p>Canada</p>
        </div>
        <p className="italic c-sand11 mt-4">
          This invoice confirms that the amount of {frmCAD(rows.reduce((acc, r) => (acc += r["Cost"]), 0))} {giftsSum > 0 && `plus ${giftsSum} as gift`} is accurate
          for my tutoring services provided in {selectedMonth.yearMonth}. I, {teacherName}, approve this amount for
          payment by Darsoon.
        </p>
      </div>
      <TableR pageId={pageId} hasHorizontalScroll={false}>
        {rows}
      </TableR>
    </>
  );
};
