import Icon from "@/components/ui/Icon";
import Button from "../ui/button";
import Input from '../ui/Input';

const Pagination = ({ table }) => {
  return (
    <div className="flex flex-wrap justify-between ac gap-8 c-gray9">
      <div className="flex ac gap-2 ">
        <Button
          iconButton
          variation="ghost"
          // className=" b-1 fw-500 px-4 py-1 rd-lg b-prm7 c-sand11 active:(b-prm8 bg-gray1) hover:(b-prm10  bg-gray1)  focus:(b-prm10  bg-gray1) disabled:(!c-gray9 !bg-prm3 !b-prm5)"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          <Icon name="i-ph-arrow-line-left" className="inline-block w-1em h-1em " />
        </Button>
        <Button
          iconButton
          variation="ghost"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <Icon name="i-ph-arrow-left" className="inline-block w-1em h-1em " />
        </Button>
        <p className="flex ac gap-2">
          Page{" "}
          <Input
            name='page-number'
            value={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            // className="field inline "
            hasErrorMessage={false}
            size={3}
          />{" "}
          of {table.getPageCount()}
        </p>
        <Button iconButton variation="ghost" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          <Icon name="i-ph-arrow-right" className="inline-block w-1em h-1em" />
        </Button>
        <Button
          iconButton
          variation="ghost"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <Icon name="i-ph-arrow-line-right" className="inline-block " />
        </Button>
      </div>
      <div className="flex gap-2 ac ">
        <span className="c-gray9">Total: {table.getPrePaginationRowModel().rows.length} Rows</span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
          className="field py-2 block"
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option className="py-2" key={pageSize} value={pageSize}>
              {pageSize} Rows per page
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Pagination;
