import Link from "next/link";
import { AccountDropdownMenu } from "../account-dropdown";
import Image from "next/image";
import useGetUserMe from "@/hooks/useGetUserMe";
import darsoonLogo from "@/public/static/logo/darsoon-logo.png";
import darsoonFarsiLogo from "@/public/static/logo/darsoon-logo-farsi.png";
import LocaleSwitcher from "../LocaleSwitcher";
import LinkButton from "../ui/button/LinkButton";
import { En, Fa } from "../ui/multilang";
import { useRouter } from "next/router";

const Header = ({ setIsSideMenuOpen, isInDashboard }) => {
  return (
    <div className="">
      <MobileHeader setIsSideMenuOpen={setIsSideMenuOpen} isInDashboard={isInDashboard} />
      <DesktopHeader isInDashboard={isInDashboard} />
    </div>
  );
};

export default Header;

function MobileHeader({ setIsSideMenuOpen, isInDashboard }) {
  const router = useRouter();
  return (
    <header
      className={`${
        !isInDashboard && router.asPath !== "/" && "bg-[#FCF9F7]/70"
      }   sm:hidden  px-4  sticky top-0 z-10 h-16 flex items-center `}
    >
      <div className={` w-full max-w-70rem mx-auto grid `} style={{ gridTemplateColumns: "1fr auto 1fr" }}>
        <div className="flex ">
          <HamburgerButton setIsSideMenuOpen={setIsSideMenuOpen} />
        </div>
        <Link href="/" className=" fw-900 c-orange10 text-xs sm:text-base flex justify-center items-center">
          <Fa>
            <Image src={darsoonFarsiLogo} alt="Darsoon" className="max-w-20" priority />
          </Fa>
          <En>
            <Image src={darsoonLogo} alt="Darsoon" className="max-w-20" priority />
          </En>
        </Link>
        <div className="flex justify-end items-center">
          <MobileAccountButtons />
        </div>
      </div>
    </header>
  );
}

function DesktopHeader({ isInDashboard }) {
  return (
    <header
      className={`  sm:flex hidden  px-4   sticky top-0 z-10 h-16 items-center c-melow  text-xs text-xs sm:text-sm  md:text-base `}
    >
      <div className={`w-full max-w-70rem mx-auto flex justify-between items-center gap-0.5 `}>
        {!isInDashboard && <PublicWebsiteNav />}
        <div className="mis-auto"></div>
        <LocaleSwitcher />
        <AccountButtons />
      </div>
    </header>
  );
}

function PublicWebsiteNav() {
  return (
    <nav className="">
      <ul className="flex items-center gap-4  ">
        <li className="flex items-center">
          <Link href="/" className=" fw-900 c-orange10 flex justify-center  items-center">
            <En>
              <Image src={darsoonLogo} alt="Darsoon" className="max-w-20" width={80} />
            </En>
            <Fa>
              <Image src={darsoonFarsiLogo} alt="Darsoon" className="max-w-20" width={80} />
            </Fa>
          </Link>
        </li>
        <li>
          <Link href="/subjects" className="hover:text-black">
            <En>Subjects</En>
            <Fa>کلاس‌ها</Fa>
          </Link>
        </li>
        <li>
          <Link href="/process" className="hover:text-black">
            <En>How it Works</En>
            <Fa>چطور کار می‌کند؟</Fa>
          </Link>
        </li>
        <li>
          <Link href="/faq" className="hover:text-black">
            <En>FAQ</En>
            <Fa>پرسش‌های پرتکرار</Fa>
          </Link>
        </li>
        <li>
          <Link href="/contact" className="hover:text-black">
            <En>Contact Us</En>
            <Fa>تماس با ما</Fa>
          </Link>
        </li>
      </ul>
    </nav>
  );
}

function AccountButtons() {
  const userMeQ = useGetUserMe();
  return (
    <div className="flex items-center ">
      <div className="flex gap-2 items-center ">
        {!userMeQ?.data && (
          <LinkButton variation="text" href="/login" width="content" className="!fw-400 ">
            <En>Login</En>
            <Fa>ورود</Fa>
          </LinkButton>
        )}
        {!userMeQ?.data && (
          <LinkButton variation="ghost" href="/signup" width="content" className=" !fw-400 ">
            <En>Sign Up</En>
            <Fa>ثبت‌نام</Fa>
          </LinkButton>
        )}
      </div>
      {!!userMeQ?.data && <AccountDropdownMenu />}
    </div>
  );
}

function MobileAccountButtons() {
  const userMeQ = useGetUserMe();
  return (
    <div>
      <div className="flex gap-4 items-center">
        {!userMeQ?.data && (
          <LinkButton variation="ghost" href="/login" className="text-xs sm:text-base !fw-400 hover:text-black">
            <En>Login</En>
            <Fa> ورود</Fa>
          </LinkButton>
        )}
      </div>
      {!!userMeQ?.data && <AccountDropdownMenu />}
    </div>
  );
}

function HamburgerButton({ setIsSideMenuOpen }) {
  return (
    <button
      className=" bf-i-ph-list before:mie-0"
      aria-label="Open Menu"
      onClick={() => setIsSideMenuOpen((state) => !state)}
    >
      <span className="sr-only">Open Menu</span>
    </button>
  );
}
