import Label from "./Label";
import ErrorMessage from "./ErrorMessage";

const classes = {
  hint: "text-xs c-sand11 italic pt-0.3em pb-0.5em",
  wrapper: {
    base: `min-h-2.75em  rd-0.5em leading-1em overflow-hidden  whitespace-nowrap
     b-1 b-sand7 hover:b-sand8
     bg-white
     grid grid-auto-flow-col
     focus-within:outline-transparent focus-within:b-prm9 focus-within:hover:b-prm9 `,
    disabled: "aria-[disabled]:cursor-not-allowed  aria-[disabled]:c-sand10",
  },
  prefixBox: "felx",
  inputElement: {
    base: ` px-0.75em  py-0.375em bg-transparent focus:outline-transparent line-height-1`,
    disabled: "",
  },
  suffixBox: "flex ",
  errorMessage: "",
};

export type FeildProps = {
  name: string;
  label?: React.ReactNode;
  hint?: React.ReactNode;
  required?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  errorMessage?: string;
  hasErrorMessage?: boolean;
  id: string;
  disabled?: boolean;
  children: React.ReactNode;
};

const Feild = function ({
  hint,
  label,
  name,
  required,
  prefix,
  suffix,
  disabled,
  errorMessage,
  hasErrorMessage = true,
  id,
  children,
}: FeildProps) {
  const hintId = hint ? `${id}-hint` : "";
  const errorMessageId = errorMessage ? `${id}-error-message` : "";
  return (
    <div className="grid ">
      <Label name={name} required={required}>
        {label}
      </Label>
      <Hint id={hintId} hint={hint} />
      <div
        className={`${classes.wrapper.base} ${disabled && classes.wrapper.disabled} `}
        style={{
          gridTemplateColumns: `${prefix ? "auto" : ""} 1fr ${suffix ? "auto" : ""}`,
        }}
      >
        <PrefixBox prefix={prefix} />
        <div className="grid">{children}</div>
        <SuffixBox suffix={suffix} />
      </div>
      {hasErrorMessage && (
        <div className="line-clamp-1  pt-0.2em" style={{ height: "var(--line-height)" }}>
          <ErrorMessage id={errorMessageId}>{errorMessage}</ErrorMessage>
        </div>
      )}
    </div>
  );
};

export default Feild;

function Hint({ hint, id }) {
  if (!hint) return <></>;
  return (
    <p className={classes.hint} id={id}>
      {hint}
    </p>
  );
}

function PrefixBox({ prefix }) {
  if (!prefix) return <></>;

  return <div className={classes.prefixBox}>{prefix}</div>;
}

function SuffixBox({ suffix }) {
  if (!suffix) return <></>;
  return <div className={classes.suffixBox}>{suffix}</div>;
}
