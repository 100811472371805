import Icon from "../Icon";

const classes = {
  messageText: "c-red11 flex items-baseline gap-1",
};

type ErrorMessageProps = {
  id: string;
  noPreStyle?: boolean;
  children?: React.ReactNode;
};

export default function ErrorMessage({ children, noPreStyle = false, id }: ErrorMessageProps) {
  if (!children) return <div role='none' aria-hidden={true} className='invisible'>Placeholder</div>;
  return (
    <p id={id} className={` ${!noPreStyle && classes.messageText}`}>
      <Icon name="bf-i-ph-warning-octagon" subdued={false} />
      <span>{children}</span>
    </p>
  );
}
