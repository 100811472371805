import { Column } from "@tanstack/react-table";
import { useMemo } from "react";
import InputDebaounced from "../InputDebounced";

export const Search = ({ globalFilter, setGlobalFilter }) => {
  return (
    <InputDebaounced
      value={globalFilter ?? ""}
      onChange={(value) => setGlobalFilter(String(value))}
      className="px-2 py-1 rd b-1 b-transparent bg-gray1  w-full fw-400 min-w-40 max-w-80 flex-1"
      placeholder={"Search all columns..."}
    />
  );
};

type ColumnType = "string" | "js-date" | "boolean" | "number" | "null";

export const TableColumnFilter = ({ columnType, column }: { columnType: ColumnType; column: Column<any, unknown> }) => {
  if (columnType === "string") return <TextFilter column={column} />;
  if (columnType === "number")
    return (
      <div className="flex">
        <input value="" className="field px-1  w-full  w-1 flex-1" disabled placeholder="filter not available" />
      </div>
    );
  if (columnType === "boolean") return <BooleanFilter column={column} />;
  if (columnType === "js-date") return <TextFilter column={column} />;
  // if (columnType === "string-date") return <DateFilter column={column} columnFilterValue={columnFilterValue} />;
  if (columnType === "null") return <span className="text-xs c-gray10">NO DATA IN THE COLUMN</span>;
  return <span className="text-xs">NO FILTER FOR THIS DATA TYPE</span>;
};

const BooleanFilter = ({ column }: { column: Column<any, unknown> }) => {
  // const map = (v) => {
  //   if (v === "TRUE") return true;
  //   if (v === "FALSE") return false;
  //   if (v === "NULL") return null;
  // };
  return (
    <>
      <select
        value={(column.getFilterValue() ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search...`}
        className="w-36 b field py-1.5 rd"
      >
        <option value="TRUE">TRUE</option>
        <option value="FALSE">FALSE</option>
        <option value="NULL">NULL</option>
      </select>
    </>
  );
};

const TextFilter = ({ column }) => {
  const sortedUniqueValues = useMemo(() => Array.from(column.getFacetedUniqueValues().keys()).sort(), [column]);

  return (
    <div className="flex">
      <datalist id={`${column.id}-list`}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <InputDebaounced
        type="text"
        value={column.getFilterValue() ?? ""}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search...`}
        className="field px-1  w-full  w-1 flex-1"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </div>
  );
};
