import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useLogOut from "@/hooks/useLogOut";
import Link from "next/link";
import Icon from "../ui/Icon";
import { En, Fa } from "../ui/multilang";
import UserMeAvatar from "../UserMeAvatar";

export function AccountDropdownMenu() {
  const logoutM = useLogOut();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="pis-2">
          <UserMeAvatar />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56 bg-gray1 " align="end">
        <DropdownMenuGroup>
          <DropdownMenuItem>
            <Icon name="bf-i-ph-user mie-2" />
            <Link href={`/account`}>
              <En>My Account</En>
              <Fa>پروفایل من</Fa>
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <Icon name="bf-i-ph-lifebuoy mie-2" />
          <Link target="_blank" href="https://wa.me/18773703277">
            <En>Support</En>
            <Fa>پشتیبانی</Fa>
          </Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <button onClick={() => logoutM.mutate()} className="">
            <Icon name="bf-i-ph-sign-out mie-2" />
            <En>Logout</En>
            <Fa>خروج</Fa>
          </button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
