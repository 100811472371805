import "@unocss/reset/sanitize/sanitize.css";
import "@unocss/reset/tailwind.css";
import "@/styles/reset.css";
import "@/styles/uno.css"; // compiled styles from unocss cli // unocss only create styles for icons (svg-in-css icons with unocss icon preset), other calsses are handled with tailwind
import "@/styles/globals.css";
import "@/public/static/fonts/azarmehr/css/azarmehr.css";
import "@/public/static/fonts/rubik/css/rubik.css";
import type { AppProps } from "next/app";
import { QueryClientProvider, QueryClient, Hydrate, DehydratedState } from "@tanstack/react-query";
import useGetUserMe from "@/hooks/useGetUserMe";
import useRouteType from "@/hooks/useRouteType";
import { useEffect, useMemo, useState } from "react";
import { createBrowserSupabaseClient } from "@supabase/auth-helpers-nextjs";
import { SessionContextProvider, Session } from "@supabase/auth-helpers-react";
import { useRouter } from "next/router";
import TimeZoneProvider from "@/components/TimeZoneProvider";
import PageLayout from "@/components/layouts/PageLayout";
import dynamic from "next/dynamic";
import { AuthChangeEvent } from "@supabase/supabase-js";
import MonthsProvider from "@/components/MonthsProvider";
import Header from "@/components/header";
import Image from "next/image";
import { GoogleTagManager } from "@next/third-parties/google";

const Footer = dynamic(() => import("@/components/Footer"), { ssr: false });
const SideMenu = dynamic(() => import("@/components/SideNav"), { ssr: false });
const LoadingSpinner = dynamic(() => import("@/components/ui/LoadingSpinner"), { ssr: false });
const Toaster = dynamic(() => import("react-hot-toast").then((mod) => mod.Toaster), {
  ssr: false,
});
const FloatingWhatsApp = dynamic(() => import("react-floating-whatsapp").then((mod) => mod.FloatingWhatsApp), {
  ssr: false,
});

function MyApp({ Component, pageProps }: AppProps<{ initialSession: Session; dehydratedState: DehydratedState }>) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  const [supabaseClient] = useState(() => createBrowserSupabaseClient());

  const [isWhatsAppShown, setIsWhatsAppShown] = useState(true);

  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const router = useRouter();
  const { pathname } = useRouter();
  const { locale } = router;
  const isRTL = locale === "fa";

  const isInDashboard = useMemo(
    () =>
      ["/admin-dashboard", "/payer-dashboard", "/teacher-dashboard", "/account"].some((path) =>
        pathname.startsWith(path)
      ),
    [pathname]
  );

  useEffect(() => {
    if (isInDashboard) {
      setIsWhatsAppShown(false);
    }
  }, [isInDashboard]);

  useEffect(() => {
    const html = document.querySelector("html");

    if (isInDashboard) {
      html.setAttribute("dir", "ltr"); // Dashboard is always LTR
    } else {
      html.setAttribute("dir", locale === "fa" ? "rtl" : "ltr"); // Set based on locale for public routes
    }
  }, [locale, isInDashboard]);

  useEffect(() => {
    supabaseClient.auth.onAuthStateChange(async (event: AuthChangeEvent, session) => {
      queryClient.invalidateQueries({ queryKey: ["userMe"] });
      if (event === "PASSWORD_RECOVERY") router.push("/account/change-password");
      if (event === "SIGNED_OUT" || event === "USER_DELETED") {
        router.push("/");
      }
    });
  }, [router, queryClient, supabaseClient]);

  return (
    <SessionContextProvider supabaseClient={supabaseClient} initialSession={pageProps.initialSession}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <TimeZoneProvider>
            <MonthsProvider>
              <RouteProtector>
                <div
                  className={`${
                    !isInDashboard && "bg-[#FCF9F7]"
                  } side-menu-container isolate  overflow-x-clip  mx-auto max-w-screen min-h-full  `}
                >
                  {isWhatsAppShown && (
                    <>
                      <FloatingWhatsApp
                        phoneNumber="+18773703277"
                        accountName="Darsoon"
                        avatar="/static/darsoon-logo-for-whatsapp.webp"
                        statusMessage="We'll get back to you as soon as possible"
                        chatMessage="We're happy to assist you in selecting the best tutor and answer any questions you may have"
                        allowClickAway={true}
                        style={{
                          zIndex: 10,
                          bottom: "2rem",
                          right: isRTL ? "unset" : "2rem",
                          left: isRTL ? "2rem" : "unset",
                        }}
                        buttonStyle={{
                          bottom: "2rem",
                          right: isRTL ? "unset" : "2rem",
                          left: isRTL ? "2rem" : "unset",
                        }}
                        chatboxStyle={{
                          bottom: "7rem",
                          right: isRTL ? "unset" : "4rem",
                          left: isRTL ? "4rem" : "unset",
                        }}
                      />
                      <button
                        onClick={() => setIsWhatsAppShown(false)}
                        className={`bf-i-ph-x text-green-800 border border-green-500 bg-white rounded-full w-7 h-7 shadow-lg z-100 c-prm10 bottom-19 ${
                          isRTL ? "left-18" : "right-7"
                        } fixed`}
                        aria-label={`${isRTL ? "بستن واتساپ" : "Close WhatsApp"}`}
                      ></button>
                    </>
                  )}
                  <div
                    className="main grid min-h-full  max-w-screen isolate"
                    style={{ gridTemplateRows: "auto 1fr auto" }}
                  >
                    <Header setIsSideMenuOpen={setIsSideMenuOpen} isInDashboard={isInDashboard} />
                    <main
                      className={`${isInDashboard ? "p-8" : router.asPath === "/" ? "" : "p-4"} h-full max-w-screen `}
                    >
                      <PageLayout>
                        <Component {...pageProps} />
                        <GoogleTagManager gtmId="GTM-NRML2DF" />
                      </PageLayout>
                    </main>
                    <Footer />
                  </div>
                  {<SideMenu {...{ isSideMenuOpen, setIsSideMenuOpen, isInDashboard }} />}
                </div>
              </RouteProtector>
            </MonthsProvider>
          </TimeZoneProvider>
          <Toaster position="bottom-right" />
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </Hydrate>
      </QueryClientProvider>
    </SessionContextProvider>
  );
}

export default MyApp;

const RouteProtector = ({ children }) => {
  const routeType = useRouteType();

  if (!routeType.type) return children;
  return <DashboardRouteProtector>{children}</DashboardRouteProtector>;
};

const DashboardRouteProtector = ({ children }) => {
  const routeType = useRouteType();
  const userMeQ = useGetUserMe();
  if (userMeQ.isLoading)
    return (
      <div className="h-full flex flex-col gap-6 jc ac ">
        <LoadingSpinner />
      </div>
    );

  if (routeType.isAdmin && !userMeQ?.data?.isAdmin)
    return <div className="flex flex-col jc ac w-full">Only admins have access to this page.</div>;
  if (routeType.isTeacher && !userMeQ?.data?.isTeacher)
    return <div className="flex flex-col jc ac w-full">Only Tutors have access to this page.</div>;
  if (routeType.isPayer && !userMeQ?.data?.isPayer)
    return <div className="flex flex-col jc ac w-full">Only Payers have access to this page.</div>;
  if (routeType.isUser && !userMeQ?.data)
    return <div className="flex flex-col jc ac w-full">Only Users have access to this page.</div>;

  return children;
};
